<template>
  <div class="flex flex-col font-Inter px-10">
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2 class="text-xl leading-7 text-gray-900 sm:text-3xl sm:truncate">
            [{{ posFiche.number }} ] {{ posFiche.title }}
          </h2>
          <p></p>
        </div>
      </div>
    </div>

    <div class="bg-white border p-4">
      <h4 class="mb-4">La liste des fiches de caisse</h4>
      <!--   <div class="flex">
        <el-input
          class="mr-4 flex-1"
          placeholder="Rechercher par le nom "
          v-model="listQuery.ref"
          @keydown.native.enter="getOperationData()"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-select
          v-model="listQuery.enbled"
          class="mr-2"
          placeholder="Sectionner la date"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="customer-white mt-2">
        <el-table :data="items" v-loading="listLoading" style="width: 100%">
          <el-table-column prop="" label="A l'ouverture">
            <el-table-column prop="" label="Date " width="170">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  <span>{{ row.date_open | filterTime }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Utilisateur">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  {{ row.user_open }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Montant" align="right" width="130">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  {{ row.amount_open | moneyFilter }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="A la clôture">
            <el-table-column prop="" label="Date " width="170">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  <span>{{ row.date_close | filterTime }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Utilisateur">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  {{ row.user_close }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="Montant" align="right" width="130">
              <template slot-scope="{ row }">
                <div class="flex flex-col">
                  {{ row.amount_close | moneyFilter }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="Documents"
              align="center"
              width="150"
            >
              <template slot-scope="{ row }">
                <el-button
                  v-if="row.status === 'CLOSE'"
                  type="text"
                  icon="el-icon-download"
                  :loading="row.open_number === rowId ? dowloandLoading : false"
                  @click="exportRapportPDF(row.open_number)"
                  >Rapport
                </el-button>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="" label="Statut" align="center" width="100">
            <template slot-scope="{ row }">
              <div class="flex flex-col">
                <el-button
                  type="text"
                  v-if="row.status === 'CLOSE'"
                  class="text-red-500"
                  >Fermée</el-button
                >
                <el-button
                  type="text"
                  v-if="row.status === 'OPEN'"
                  class="text-green-500"
                  >Ouverte</el-button
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column width="120" label="Action" align="right">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                @click="handleListeTicket(row.open_number)"
                icon="el-icon-tickets"
                circle
              >
              </el-button>
              <el-button size="mini" icon="el-icon-edit" circle> </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="listQuery.total > 0"
          :total="listQuery.total"
          :page.sync="listQuery.page"
          :limit.sync="listQuery.size"
          @pagination="getOperationData()"
        />
      </div>
    </div>
    <list-ticket
      :visible="dialogListVisible"
      @close-dialog="handleListeDialog"
      :ficheNumber="openNumber"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ListTicket from "./components/ticket-list.vue";
import { fetchFichePos, fetchPos } from "@/api/sales2";
import { exportPOSRapportPDF } from "@/api/export";
import { toThousandFilter } from "@/Filters";
import { parseTime } from "@/utils";

export default {
  name: "POS",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { Pagination, ListTicket },
  data() {
    return {
      dialogListVisible: false,
      openNumber: "",
      dowloandLoading: false,
      rowId: "",
      options: [
        {
          value: "ALL",
          label: "Tous",
        },
        {
          value: "ACTIF",
          label: "Actif",
        },
        {
          value: "DESACTIF",
          label: "Désactif",
        },
      ],
      listLoading: false,
      loadingButton: false,
      dialogVisiblePos: false,
      items: [],
      accounts: [],
      labelPosition: "top",
      posForm: {
        title: "",
        description: "",
        account_numbers: "",
      },
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        posNumber: this.$route.params && this.$route.params.id,
        status: undefined,
        userOpen: undefined,
        userClose: undefined,
      },
      posFiche: {
        amount_close: 0,
        amount_open: 0,
        date_close: "",
        date_open: "",
        note_close: "",
        note_open: "",
        openclose_id: "",
        pos_name: " ",
        status: "",
        user_close: "",
        user_open: "",
      },
    };
  },
  created() {},
  mounted() {
    this.fetchInfoFiche();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.listLoading = true;
      await fetchFichePos(this.listQuery).then((res) => {
        setTimeout(() => {
          this.items = res.data.items;
          this.listQuery.total = parseInt(res.data.total_items);
          this.listQuery.page = parseInt(res.data.current_page) + 1;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    async fetchInfoFiche() {
      const id = this.$route.params && this.$route.params.id;
      await fetchPos(id)
        .then((res) => {
          this.posFiche = { ...res.data };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleListeDialog() {
      this.dialogListVisible = !this.dialogListVisible;
    },
    handleListeTicket(number) {
      this.openNumber = number;
      console.log(this.openNumber);
      this.handleListeDialog();
    },
    exportRapportPDF(number) {
      this.dowloandLoading = true;
      this.rowId = number;
      exportPOSRapportPDF(number)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");

            link.href = url;
            link.setAttribute(
              "download",
              "rapport-caisse-numero" + number + ".pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.dowloandLoading = false;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
